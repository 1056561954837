import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { MediaBuyMetric } from 'shared/src/metrics-types';
import Palette from 'iwanthue/palette';
import { formatDate } from 'shared/src/date-utils';
import { MetricOption } from './media-buy-performance';

export type DisplayMode = 'cumulative' | 'discrete';

interface Props {
  metrics: MediaBuyMetric[];
  displayMode: DisplayMode;
  metricOption: MetricOption;
}

export function MediaBuyMetricsChart({ metrics, displayMode, metricOption }: Props) {
  const { metricType } = metricOption;

  const metric = displayMode === 'cumulative' ? metricOption.cumulativeMetric : metricOption.metric;
  const plannedMetric =
    displayMode === 'cumulative'
      ? metricOption.plannedCumulativeMetric
      : metricOption.plannedMetric;

  function formatNumber(value: any) {
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    if (typeof value === 'number') {
      return metricType == 'percentage'
        ? `${formatter.format(value * 100)}%`
        : `${metricType == 'currency' ? '$' : ''}${formatter.format(value)}`;
    }
    return '';
  }

  const { barData, lineData, palette } = useMemo(() => {
    const barData = metrics.map(d => ({
      day: d.BUCKET.toString(),
      delivered: d[metric],
      planned: d[plannedMetric],
      budget_block: d.LINE_ITEM_BUDGET_BLOCK
    }));
    const budgetBlockMetrics = getBudgetBlockMetrics(metrics);
    const blocks = Object.keys(budgetBlockMetrics).sort();
    const palette = Palette.generateFromValues('blocks', blocks, {
      defaultColor: '#000',
      clustering: 'force-vector',
      seed: 'cool-palette',
      quality: 100
    });
    const lineData = blocks.map(key => ({
      id: key,
      data: budgetBlockMetrics[key].map(d => ({
        x: d.BUCKET.toString(),
        y: d[metric]
      }))
    }));
    return {
      barData,
      lineData,
      palette
    };
  }, [metrics, metric, plannedMetric]);

  // Calculate common y-axis max value
  const maxY = Math.max(...metrics.map(d => d[plannedMetric]), ...metrics.map(d => d[metric]));

  return (
    <div className={'mt-2'} style={{ height: 500, width: '100%', position: 'relative' }}>
      <div style={{ height: '100%', width: '100%', position: 'absolute', pointerEvents: 'none' }}>
        <ResponsiveBar
          data={barData}
          keys={['planned']}
          indexBy="day"
          indexScale={{ type: 'band', round: true }}
          margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
          padding={0.2}
          colors={({ data }) => {
            // Add the alpha to the end to make it transparent for the bar graph
            return `${palette.get(data.budget_block as string)}40`;
          }}
          axisBottom={null}
          axisLeft={null}
          enableLabel={false}
          tooltip={undefined}
          maxValue={maxY} // Use the same max value for both charts
        />
      </div>

      <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
        <ResponsiveLine
          data={lineData}
          margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
          xScale={{ type: 'band', round: true }}
          yScale={{ type: 'linear', min: 0, max: maxY, stacked: false, reverse: false }} // Set yScale with common max
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 0,
            format: value => {
              const date = new Date(value);
              const day = date.getDate();
              if (day === 1 || day === 15) {
                return formatDate(date, 'dd/MM');
              }
              return '';
            }
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Units Delivered',
            legendPosition: 'middle',
            legendOffset: -50,
            format: value => {
              return formatNumber(value);
            }
          }}
          axisTop={null}
          axisRight={null}
          legends={[
            {
              anchor: 'top-left',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -30,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'right-to-left',
              itemOpacity: 0.85,
              symbolSize: 20,
              symbolShape: 'circle'
            }
          ]}
          gridXValues={[]}
          gridYValues={[]}
          colors={palette.colors()} // Color for the line chart
          lineWidth={3}
          pointSize={0}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          crosshairType="x" // Add a vertical line to indicate the x position
          tooltip={({ point }) => (
            <div
              style={{
                background: 'white',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
                textAlign: 'center'
              }}>
              <div>
                Delivered <strong>{formatNumber(metrics[point.index][metric])}</strong> of{' '}
                <strong>{formatNumber(metrics[point.index][plannedMetric])}</strong>
              </div>
              <div>
                on <strong>{formatDate(new Date(point.data.x))}</strong>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export function getBudgetBlockMetrics(array: MediaBuyMetric[]): {
  [key: string]: MediaBuyMetric[];
} {
  return array.reduce<{ [key: string]: MediaBuyMetric[] }>((acc, item) => {
    const { LINE_ITEM_BUDGET_BLOCK } = item;
    if (!acc[LINE_ITEM_BUDGET_BLOCK]) {
      acc[LINE_ITEM_BUDGET_BLOCK] = [];
    }
    acc[LINE_ITEM_BUDGET_BLOCK].push(item);
    return acc;
  }, {});
}
