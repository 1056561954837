import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './use-auth';
import Intercom from '@intercom/messenger-js-sdk';
import { usePostHog } from 'posthog-js/react';

type Props = {
  children: ReactNode;
};

export function ProtectedRoute({ children }: Props) {
  const { isLoggedIn, loading, identity } = useAuth();
  const posthog = usePostHog();

  if (loading) {
    return null;
  } else if (!isLoggedIn) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  if (identity) {
    Intercom({
      app_id: 'r9m7ot8l',
      user_id: identity.email, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: identity.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: identity.email // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    });
    posthog.identify(
      identity.email,
      { email: identity.email, name: identity.name } // optional: set additional person properties
    );
  }
  return children;
}
