import { z } from 'zod';
import { useEffect, useState } from 'react';
import { client } from '../../utils/trpc-client';
import { MediaBuyMetric, MediaBuyMetricsInput } from 'shared/src/metrics-types';

// make a zod type for SampleMetricsPayload
export const SampleMetricsPayload = z.object({
  mediaBuys: z.array(z.object({ name: z.string(), value: z.string() }))
});

export type SampleMetricsPayload = z.infer<typeof SampleMetricsPayload>;

export type MediaBuyMetrics = {
  data: MediaBuyMetric[];
  loading: boolean;
  selectedMediaBuyId: string | null;
  setSelectedMediaBuyId: (id: string) => void;
};

export function useSampleMetrics(payload: SampleMetricsPayload): MediaBuyMetrics {
  const [mediaBuyMetrics, setMediaBuyMetrics] = useState<MediaBuyMetric[]>([]);
  const [selectedMediaBuyId, setSelectedMediaBuyId] = useState<string | null>(
    payload.mediaBuys[0].value
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      if (selectedMediaBuyId) {
        try {
          setLoading(true);
          const params: MediaBuyMetricsInput = { media_buy_id: selectedMediaBuyId, bucket: 'day' };
          setMediaBuyMetrics(await client.mediaBuyMetrics.query(params));
        } finally {
          setLoading(false);
        }
      }
    }

    loadData();
  }, [selectedMediaBuyId]);

  return {
    data: mediaBuyMetrics,
    loading,
    selectedMediaBuyId,
    setSelectedMediaBuyId
  };
}
