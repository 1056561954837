import { z } from 'zod';

// TODO[mk] - change to draft and finalised

export const MediaBuyCampaign = z.object({
  id: z.string(),
  line_item_id: z.string(),
  media_platform_id: z.number(),
  platform_entity_id: z.string(),
  raw_entity: z.object({}).catchall(z.any()),
  name: z.string()
});

export type MediaBuyCampaign = z.infer<typeof MediaBuyCampaign>;

export const NewMediaBuyCampaign = MediaBuyCampaign.omit({
  platform_entity_id: true,
  raw_entity: true
});

export type NewMediaBuyCampaign = z.infer<typeof NewMediaBuyCampaign>;

export const MediaBuy = z.object({
  id: z.string(),
  line_item_id: z.string(),
  // TODO could use media_buy_campaign but queries would need to be updated
  media_buy_campaign_id: z.string().optional().nullable(),
  media_platform_id: z.number(),
  platform_entity_id: z.string(),
  raw_entity: z.object({}).catchall(z.any()),
  name: z.string(),
  start_date: z.coerce.date(),
  end_date: z.coerce.date(),
  budget: z.coerce.number()
});

export type MediaBuy = z.infer<typeof MediaBuy>;

export const NewMediaBuy = MediaBuy.omit({
  platform_entity_id: true,
  raw_entity: true,
  media_buy_campaign_id: true
});

export type NewMediaBuy = z.infer<typeof NewMediaBuy>;

export const MediaBuyUpdate = MediaBuy.partial()
  .omit({ id: true, line_item_id: true, raw_entity: true })
  .extend({ id: z.string(), line_item_id: z.string() });

export type MediaBuyUpdate = z.infer<typeof MediaBuyUpdate>;

export const MediaBuyChanges = z.union([
  z.object({
    type: z.literal('new'),
    data: NewMediaBuy
  }),
  z.object({
    type: z.literal('update'),
    data: MediaBuyUpdate
  })
]);
export type MediaBuyChanges = z.infer<typeof MediaBuyChanges>;
