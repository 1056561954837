import React, { useCallback, useEffect, useState } from 'react';
import { CustomStatusPanelProps } from '@ag-grid-community/react';
import { LineItemTableData } from '../line-items/line-items-table';
import { GridApi } from '@ag-grid-community/core';
import { LoadingIcon } from './loading-icon';

type LoadingState = { state: 'loading' } | { state: 'loaded'; count: number };

export function CampaignTotalStatusPanel({ api }: CustomStatusPanelProps<LineItemTableData>) {
  const [loadingState, setLoadingState] = useState<LoadingState>({ state: 'loading' });

  const updateStatusBar = useCallback(() => {
    setLoadingState(getLoadingState(api));
  }, [api]);

  useEffect(() => {
    api.addEventListener('modelUpdated', updateStatusBar);
    return () => {
      if (!api.isDestroyed()) api.removeEventListener('modelUpdated', updateStatusBar);
    };
  }, [api, updateStatusBar]);

  return (
    <div className="flex justify-end py-3">
      {loadingState.state === 'loading' ? (
        <div className="flex">
          <div>Fetching Rows</div>
          <LoadingIcon />
        </div>
      ) : (
        <MatchingRows totalCount={loadingState.count} />
      )}
      <div className="w-10" />
    </div>
  );
}

function MatchingRows({ totalCount }: { totalCount: number }) {
  return totalCount > 0 ? (
    <span>
      Total Matching Rows: <span className="font-bold">{totalCount}</span>
    </span>
  ) : (
    <span>No matching rows found, try updating your filter</span>
  );
}

function getLoadingState(api: GridApi<LineItemTableData>): LoadingState {
  const cacheBlocks: unknown = api.getCacheBlockState();

  if (typeof cacheBlocks === 'object' && cacheBlocks && Object.keys(cacheBlocks).length === 0) {
    // When there are no rows, we have a cacheblocks object, but it's empty, so no way to
    // check loading status. Assume that rows are loaded with a zero count
    return { state: 'loaded', count: 0 };
  }

  if (typeof cacheBlocks === 'object' && cacheBlocks && 0 in cacheBlocks) {
    return isCacheBlockLoaded(cacheBlocks[0])
      ? { state: 'loaded', count: api.getInfiniteRowCount() || 0 }
      : { state: 'loading' };
  }
  return {
    state: 'loading'
  };
}

// Defensively check if cacheBlockState object exists and has a pageStatus of 'loaded'
function isCacheBlockLoaded(cacheBlock: unknown): boolean {
  return !!(
    typeof cacheBlock === 'object' &&
    cacheBlock &&
    'pageStatus' in cacheBlock &&
    typeof cacheBlock.pageStatus === 'string' &&
    cacheBlock.pageStatus === 'loaded'
  );
}
