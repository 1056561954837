import React, { useState } from 'react';
import { MediaBuyMetricsChart } from './media-buy-metrics-chart';
import { SampleMetricsPayload, useSampleMetrics } from './use-media-buy-metrics';
import { clsx } from 'clsx';
import { useFeatureFlagPayload } from 'posthog-js/react';

export type DisplayMode = 'cumulative' | 'discrete';

const metricOptions = [
  {
    type: 'impressions',
    metric: 'UNITS_DELIVERED',
    plannedMetric: 'UNITS_PLANNED_TO_DELIVER',
    cumulativeMetric: 'UNITS_DELIVERED_CUMULATIVE',
    plannedCumulativeMetric: 'UNITS_PLANNED_TO_DELIVER_CUMULATIVE',
    metricType: 'number'
  },
  {
    type: 'price',
    metric: 'REVENUE_EARNED',
    plannedMetric: 'REVENUE_PLANNED_TO_EARN',
    cumulativeMetric: 'REVENUE_EARNED_CUMULATIVE',
    plannedCumulativeMetric: 'REVENUE_PLANNED_TO_EARN_CUMULATIVE',
    metricType: 'currency'
  },
  {
    type: 'target',
    metric: 'PERCENT_REVENUE_EARNINGS_TARGET_MET_OVERALL',
    plannedMetric: 'PERCENT_REVENUE_EARNINGS_TARGET_MET_OVERALL',
    cumulativeMetric: 'PERCENT_REVENUE_EARNINGS_TARGET_MET_OVERALL',
    plannedCumulativeMetric: 'PERCENT_REVENUE_EARNINGS_TARGET_MET_OVERALL',
    metricType: 'percentage'
  }
] as const;

export type MetricOption = (typeof metricOptions)[number];

export function MediaBuyPerformance() {
  const payload = useFeatureFlagPayload('sample-metrics-data');
  return payload ? <MediaBuyGraph payload={SampleMetricsPayload.parse(payload)} /> : null;
}

function MediaBuyGraph({ payload }: { payload: SampleMetricsPayload }) {
  const { data, selectedMediaBuyId, setSelectedMediaBuyId, loading } = useSampleMetrics(payload);
  const [graphDisplayMode, setGraphDisplayMode] = useState<DisplayMode>('discrete');
  const [metricOption, setMetricOption] = useState<MetricOption>(metricOptions[0]);

  return (
    <div className="mb-8 flex flex-col">
      <div className="flex items-center justify-between">
        <div className="pr-16 text-lg font-bold">Media Buy Performance</div>
        <div className="ml-4 mr-0 items-center">
          <label htmlFor="options" className="mr-2 text-xs text-gray-700">
            Viewing:
          </label>
          <select
            id="options"
            className="rounded border-0 border-gray-300 text-xs"
            onChange={event =>
              setMetricOption(metricOptions.find(item => item.type === event.target.value)!)
            }
            value={metricOption.type}>
            {metricOptions.map(item => (
              <option key={item.type} value={item.type}>
                {item.type}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <DisplayModeSelector
          graphDisplayMode={graphDisplayMode}
          setGraphDisplayMode={setGraphDisplayMode}
        />
        {selectedMediaBuyId && (
          <div className="ml-4 mr-0 flex items-center">
            <label htmlFor="options" className="mr-2 text-xs text-gray-700">
              Sample Data:
            </label>
            <select
              id="options"
              className="rounded border-0 border-gray-300 text-xs"
              onChange={event => setSelectedMediaBuyId(event.target.value)}
              value={selectedMediaBuyId}>
              {payload.mediaBuys.map(item => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div className="flex flex-1 items-center justify-center align-middle">
        {(data.length == 0 && (
          <div className="flex h-32 items-center justify-center rounded bg-gray-50 px-32 align-middle shadow">
            {loading ? 'Loading...' : 'No Data Available'}
          </div>
        )) || (
          <MediaBuyMetricsChart
            metrics={data}
            displayMode={graphDisplayMode}
            metricOption={metricOption}
          />
        )}
      </div>
    </div>
  );
}

type DisplayModeSelectorProps = {
  graphDisplayMode: DisplayMode;
  setGraphDisplayMode: (mode: DisplayMode) => void;
};

function DisplayModeSelector({ graphDisplayMode, setGraphDisplayMode }: DisplayModeSelectorProps) {
  return (
    <div className="mb-2 mt-3 flex">
      <DisplayModeButton
        onClick={() => setGraphDisplayMode('discrete')}
        label="Daily"
        classes={clsx(
          'rounded-l-lg',
          graphDisplayMode === 'discrete' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700'
        )}
      />
      <DisplayModeButton
        onClick={() => setGraphDisplayMode('cumulative')}
        classes={clsx(
          'rounded-r-lg',
          graphDisplayMode === 'cumulative' ? 'bg-blue-600 text-white' : 'bg-white text-gray-700'
        )}
        label="Cumulative"
      />
    </div>
  );
}

type DisplayModeButtonProps = {
  onClick: () => void;
  label: string;
  classes: string;
};

function DisplayModeButton({ onClick, label, classes }: DisplayModeButtonProps) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'inline-flex items-center border-b border-r border-t px-4 py-2 text-sm font-medium shadow-sm hover:bg-blue-700 hover:text-white focus:outline-none disabled:bg-blue-500',
        classes
      )}>
      {label}
    </button>
  );
}
