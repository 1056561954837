import { DraftStrategyChanges, Strategy } from 'shared/src/types';
import { mapValues } from 'lodash';
import { LineItem, PartialLineItem } from 'shared/src/line-item-types';
import { MediaBuy, MediaBuyChanges } from 'shared/src/media-buy-types';

// TODO - [mk] Removing Line Items

export type ChangeState = 'unchanged' | 'new' | 'updated';

export type CombinedMediaBuy = MediaBuy & {
  state: ChangeState;
  dirty: { [K in keyof MediaBuy]?: true };
};

export type Dirty = {
  [K in keyof LineItem]?: { old: LineItem[K]; new: LineItem[K] | undefined } | null;
};

export type UpdatedLineItemState = {
  type: 'update';
  original: LineItem;
  dirty: Dirty;
};

export type NewLineItemState = {
  type: 'new';
};

export type UnchangedLineItemState = {
  type: 'unchanged';
};

export type LineItemChangeState = UpdatedLineItemState | NewLineItemState | UnchangedLineItemState;

export type CombinedLineItem = PartialLineItem & {
  state: LineItemChangeState;
  media_buys: Array<CombinedMediaBuy>;
};

export type CombinedStrategy = Omit<Strategy, 'line_items'> & {
  line_items: Array<CombinedLineItem>;
  changes: DraftStrategyChanges;
};

export function combineStrategies(
  strategy: Strategy,
  changes: DraftStrategyChanges
): CombinedStrategy {
  return {
    ...strategy,
    line_items: [
      ...strategy.line_items.map(lineItem => combineLineItem(lineItem, changes)),
      ...mapNewLineItems(changes)
    ],
    changes
  };
}

function combineLineItem(lineItem: LineItem, changes: DraftStrategyChanges): CombinedLineItem {
  const update = changes.line_items[lineItem.id];
  if (update && update.type === 'new')
    throw new Error('A server line item cannot also be created locally');
  return {
    ...lineItem,
    ...(update ? update.data : {}),
    state: update
      ? { type: 'update', original: lineItem, dirty: calcDirtyLineItems(lineItem, update.data) }
      : { type: 'unchanged' },
    media_buys: [
      ...lineItem.media_buys.map(mediaBuy => combineMediaBuy(mediaBuy, changes)),
      ...mapNewMediaBuys(lineItem.id, changes.media_buys)
    ]
  };
}

function calcDirtyLineItems(lineItem: LineItem, update: PartialLineItem): Dirty {
  // tactic and unit_price_type can be reset to undefined

  return {
    ...('name' in update ? { name: { old: lineItem.name, new: update.name } } : {}),
    ...('channel' in update ? { channel: { old: lineItem.channel, new: update.channel } } : {}),
    ...('tactic' in update ? { tactic: { old: lineItem.tactic, new: update.tactic } } : {}),
    ...('unit_price_type' in update
      ? { unit_price_type: { old: lineItem.unit_price_type, new: update.unit_price_type } }
      : {}),
    ...('geo' in update ? { geo: { old: lineItem.geo, new: update.geo } } : {}),
    ...('targeting' in update
      ? { targeting: { old: lineItem.targeting, new: update.targeting } }
      : {}),
    ...('ad_formats' in update
      ? { ad_formats: { old: lineItem.ad_formats, new: update.ad_formats } }
      : {}),
    ...('audience' in update ? { audience: { old: lineItem.audience, new: update.audience } } : {}),
    ...('price' in update ? { budget: { old: lineItem.price, new: update.price } } : {}),
    ...('target_margin' in update
      ? { target_margin: { old: lineItem.target_margin, new: update.target_margin } }
      : {}),
    ...('unit_price' in update
      ? { unit_price: { old: lineItem.unit_price, new: update.unit_price } }
      : {}),
    ...('start_date' in update
      ? { start_date: { old: lineItem.start_date, new: update.start_date } }
      : {}),
    ...('end_date' in update ? { end_date: { old: lineItem.end_date, new: update.end_date } } : {}),
    ...('pacing_type' in update
      ? { pacing_type: { old: lineItem.pacing_type, new: update.pacing_type } }
      : {}),
    ...('pacing_details' in update
      ? { pacing_details: { old: lineItem.pacing_details, new: update.pacing_details } }
      : {}),
    ...('media_traders' in update
      ? { media_traders: { old: lineItem.media_traders, new: update.media_traders } }
      : {}),
    ...('media_platforms' in update
      ? { media_platforms: { old: lineItem.media_platforms, new: update.media_platforms } }
      : {}),
    ...('is_deleted' in update
      ? { is_deleted: { old: lineItem.is_deleted, new: update.is_deleted } }
      : {})
  };
}

function mapNewLineItems(changes: DraftStrategyChanges): Array<CombinedLineItem> {
  return Object.values(changes.line_items)
    .filter(change => change.type === 'new')
    .map(newLineItem => ({
      ...newLineItem.data,
      state: { type: 'new' },
      media_buys: mapNewMediaBuys(newLineItem.data.id, changes.media_buys)
    }));
}

function mapNewMediaBuys(
  lineItemId: string,
  mediaBuys: Record<string, MediaBuyChanges>
): CombinedMediaBuy[] {
  return Object.values(mediaBuys)
    .filter(change => change.type === 'new')
    .filter(change => change.data.line_item_id === lineItemId)
    .map(newMediaBuy => ({
      ...newMediaBuy.data,
      raw_entity: {},
      platform_entity_id: '',
      state: 'new' as const,
      dirty: {}
    }));
}

function combineMediaBuy(mediaBuy: MediaBuy, changes: DraftStrategyChanges): CombinedMediaBuy {
  const update = changes.media_buys[mediaBuy.id];

  if (update && update.type === 'new')
    throw new Error('A media buy cannot also be created locally');

  return {
    ...mediaBuy,
    ...(update ? update.data : {}),
    state: update ? 'updated' : 'unchanged',
    dirty: update ? mapValues({ ...update.data }, () => true as const) : {}
  };
}
