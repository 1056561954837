import { z } from 'zod';

export type CreateCampaignOptions = {
  advertiser_id: number;
  campaign_name: string;
  campaign_budget: number;
  start_date: string;
  end_date: string;
  active: boolean;
};

export type BiddingInput = {
  bidding_strategy: 'CPM';
  bid_shading?: boolean;
  bid_shading_win_rate_control?: 'NORMAL';
  pacing?: 'lifetime' | 'none';
  values: Record<string, string | number>;
};

export type BeeswaxPostBody = Record<string, string | boolean | number | object>;

export type CreateLineItemOptions = {
  campaign_id: number;
  advertiser_id: number;
  // 0=banner, 1=video, 2=native
  line_item_type_id: 0 | 1 | 2;
  line_item_name: string;
  line_item_budget: number;
  bidding: BiddingInput;
  start_date: string;
  end_date: string;
  active: boolean;
};

export const BeeswaxCreateResponse = z.object({
  success: z.boolean(),
  payload: z.object({
    id: z.number()
  }),
  message: z.string()
});

export type CreateCampaignResponse = z.infer<typeof BeeswaxCreateResponse>;

export const BeeswaxErrorResponse = z.object({
  success: z.literal(false),
  errors: z.array(z.string())
});

export type BeeswaxErrorResponse = z.infer<typeof BeeswaxErrorResponse>;

export const BeeswaxGetResponse = z.object({
  success: z.literal(true),
  payload: z.array(z.object({}).catchall(z.any()))
});
export type BeeswaxGetResponse = z.infer<typeof BeeswaxGetResponse>;

const BiddingObject = z.object({
  values: z.object({
    cpm_bid: z.number()
  }),
  bidding_strategy: z.string()
});

export const BeeswaxCampaign = z.object({
  advertiser_id: z.number(),
  campaign_name: z.string(),
  campaign_budget: z.number(),
  start_date: z.coerce.date(),
  end_date: z.union([z.coerce.date(), z.null()]),
  active: z.boolean()
});

export type BeeswaxCampaign = z.infer<typeof BeeswaxCampaign>;

export const BeeswaxLineItem = z.object({
  advertiser_id: z.number(),
  bidding: BiddingObject,
  budget_type: z.number(),
  campaign_id: z.number(),
  line_item_budget: z.number(),
  line_item_id: z.number(),
  line_item_name: z.string(),
  line_item_spend: z.number(),
  line_item_type_id: z.number(),
  start_date: z.coerce.date(),
  end_date: z.union([z.coerce.date(), z.null()]),
  account_id: z.number(),
  create_date: z.string(),
  update_date: z.string(),
  active: z.boolean()
});
export type BeeswaxLineItem = z.infer<typeof BeeswaxLineItem>;

export const BeeswaxLineItemResponse = z.object({
  success: z.literal(true),
  payload: z.array(BeeswaxLineItem)
});
export type BeeswaxLineItemResponse = z.infer<typeof BeeswaxLineItemResponse>;
