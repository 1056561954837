import { createTRPCClient, httpLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { AppRouter } from 'packages/backend/src/app-router';
import superjson from 'superjson';

export const trpc = createTRPCReact<AppRouter>();

// Used when we need to make a backend request in an imperative way
export const client = createTRPCClient<AppRouter>({
  links: [httpLink({ url: '/api', transformer: superjson })]
});
